import { render, staticRenderFns } from "./BankCardEditComponent.vue?vue&type=template&id=4540a918"
import script from "./BankCardEditComponent.vue?vue&type=script&lang=js"
export * from "./BankCardEditComponent.vue?vue&type=script&lang=js"
import style0 from "./BankCardEditComponent.vue?vue&type=style&index=0&id=4540a918&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports